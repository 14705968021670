"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _grid = require("@rebass/grid");
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _getUnitTypeConsts = _interopRequireDefault(require("../constants/getUnitTypeConsts"));
var _TenantContext = require("../context/TenantContext");
var _graphql = require("../generated/graphql");
var _createPlaceholder = _interopRequireDefault(require("../utils/createPlaceholder"));
var _TitleWrapper = _interopRequireDefault(require("./designsystem/TitleWrapper"));
var _UnitThumbnail = _interopRequireDefault(require("./designsystem/UnitThumbnail"));
var _Divider = _interopRequireDefault(require("./Divider"));
var _Loading = _interopRequireDefault(require("./Loading"));
var _ReviewForm = _interopRequireDefault(require("./ReviewForm"));
var _SummaryBar = _interopRequireDefault(require("./SummaryBar"));
var _SummaryBarItem = _interopRequireWildcard(require("./SummaryBarItem"));
var _useGetPageSlugByLocale = _interopRequireDefault(require("../hooks/useGetPageSlugByLocale"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const LeaveReview = ({
  booking,
  leftReview: isReviewDone,
  setLeftReview,
  rentalUnit,
  isAnonymous,
  loading
}) => {
  const [mutate] = (0, _graphql.useLeaveReviewMutation)();
  const [unauthenticatedMutate] = (0, _graphql.useLeaveUnauthenticatedReviewMutation)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const {
    getPageSlugByLocale
  } = (0, _useGetPageSlugByLocale.default)();
  const accommodationPageSlug = getPageSlugByLocale('ACCOMMODATION');
  const {
    push
  } = (0, _router.useRouter)();
  const intl = (0, _reactIntl.useIntl)();
  const {
    query: {
      bookingHash
    },
    locale
  } = (0, _router.useRouter)();
  const handleSubmit = async values => {
    if (isAnonymous) {
      if (!bookingHash) {
        return;
      }
      await unauthenticatedMutate({
        variables: {
          input: _objectSpread({
            bookingHash: bookingHash
          }, values)
        }
      });
      setLeftReview(true);
      return;
    }
    if (!booking?.id) {
      return;
    }
    await mutate({
      variables: {
        input: _objectSpread({
          bookingId: booking.id
        }, values)
      },
      update: (cache, {
        data: payload
      }) => {
        const opts = {
          id: `${booking?.__typename ?? 'Booking'}:${booking.id}`,
          fragment: _graphql.OrderItemFragmentDoc,
          fragmentName: 'OrderItem'
        };
        const old = cache?.readFragment(opts);
        if (!old || !payload?.leaveReview.review) {
          return;
        }
        cache.writeFragment(_objectSpread(_objectSpread({}, opts), {}, {
          data: _objectSpread(_objectSpread({}, old), {}, {
            review: payload?.leaveReview.review
          })
        }));
      }
    });
    setLeftReview(true);
  };
  if (isReviewDone) {
    return __jsx(_grid.Flex, {
      flexDirection: ['column', null, 'row'],
      justifyContent: "space-between"
    }, __jsx(_grid.Box, {
      order: 1,
      mr: [null, null, 5, 8]
    }, __jsx(StyledTitleWrapper, {
      title: __jsx(_reactIntl.FormattedMessage, {
        id: "BSaBd+",
        defaultMessage: [{
          "type": 0,
          "value": "Bedankt voor je beoordeling"
        }]
      })
    }), __jsx(Description, null, __jsx(_reactIntl.FormattedMessage, {
      id: "+4q/aA",
      defaultMessage: [{
        "type": 0,
        "value": "We hebben je beoordeling gepubliceerd op de website. Je kunt deze "
      }, {
        "type": 1,
        "value": "here"
      }, {
        "type": 0,
        "value": " bekijken."
      }],
      values: {
        here:
        // we've left a review, shown a "thank you" part, so now we can show the review when the user wants to
        __jsx(_TextButton.default, {
          showBorderAlways: true,
          onClick: () => isAnonymous ? push('/[accommodation]/[slug]', {
            pathname: `/${accommodationPageSlug}/${rentalUnit?.slug}`
          }) : setLeftReview(null)
        }, __jsx(_reactIntl.FormattedMessage, {
          id: "re8oEl",
          defaultMessage: [{
            "type": 0,
            "value": "hier"
          }]
        }))
      }
    }), __jsx("br", null), __jsx("br", null), __jsx(_reactIntl.FormattedMessage, {
      id: "2Sv62q",
      defaultMessage: [{
        "type": 0,
        "value": "We zien je graag in de toekomst terug op ons mooie eiland!"
      }]
    }), __jsx("br", null), __jsx("br", null), brandConfig?.surveyUrl?.[locale] && __jsx(_react.default.Fragment, null, __jsx("b", null, __jsx(_reactIntl.FormattedMessage, {
      id: "qn3cpn",
      defaultMessage: [{
        "type": 0,
        "value": "Win een weekend op Texel"
      }]
    })), __jsx("br", null), __jsx(_reactIntl.FormattedMessage, {
      id: "D5uWFo",
      defaultMessage: [{
        "type": 0,
        "value": "De ondernemers op het eiland willen hun dienstverlening graag continu verbeteren. Jouw mening over Texel als vakantiebestemming is daarbij heel belangrijk."
      }]
    }), __jsx("br", null), __jsx("br", null), __jsx(_reactIntl.FormattedMessage, {
      id: "fWWgx2",
      defaultMessage: [{
        "type": 0,
        "value": "Met het invullen van een korte enquête deel je jouw ervaringen tijdens je verblijf op het eiland. Bijvoorbeeld jouw ervaring met het aanbod en de service in de horeca, de winkels en de musea op Texel."
      }]
    }), __jsx("br", null), __jsx("br", null), __jsx(_reactIntl.FormattedMessage, {
      id: "gUJGDh",
      defaultMessage: [{
        "type": 0,
        "value": "Onder de inzenders verloten we jaarlijks op 1 december een verblijf op Texel (geldig in het volgende kalenderjaar) in de vorm van een cadeaubon van € 500, te besteden bij een telefonische boeking via VVV Texel"
      }]
    }), __jsx("br", null), __jsx("a", {
      href: brandConfig.surveyUrl[locale],
      target: "_blank",
      rel: "noreferrer"
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "4c+w8z",
      defaultMessage: [{
        "type": 0,
        "value": "Jouw ervaringen op Texel"
      }]
    }))))), __jsx(_grid.Box, {
      order: 1,
      mb: 6,
      ml: [3, null, 4],
      width: "30%",
      className: "gt-m"
    }, __jsx(_SummaryBar.default, {
      data: booking,
      showOnMobile: true,
      hidePrices: true
    })));
  }
  return __jsx(_react.default.Fragment, null, __jsx(_grid.Flex, {
    flexDirection: ['column', null, 'row'],
    justifyContent: "space-between"
  }, __jsx(_grid.Box, {
    order: 1,
    mr: [null, null, 5, 8]
  }, __jsx(StyledTitleWrapper, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "Nu7jsd",
      defaultMessage: [{
        "type": 0,
        "value": "Je beoordeling"
      }]
    })
  }), __jsx(Description, null, __jsx(_reactIntl.FormattedMessage, {
    id: "LwydMc",
    defaultMessage: [{
      "type": 0,
      "value": "Wat fijn dat je de tijd neemt om een beoordeling te geven van je accommodatie. Je kunt hiervoor onderstaand formulier gebruiken."
    }]
  }), __jsx("br", null), __jsx("br", null), __jsx(_reactIntl.FormattedMessage, {
    id: "1GczhI",
    defaultMessage: [{
      "type": 0,
      "value": "Je beoordeling wordt op de website onder de door jouw beoordeelde accommodatie getoond. We vermelden daarbij alleen je woonplaats en de maand waarin je te gast was."
    }]
  }), __jsx("br", null), __jsx("br", null), __jsx(_reactIntl.FormattedMessage, {
    id: "9kCIiI",
    defaultMessage: [{
      "type": 0,
      "value": "De eigenaar van de accommodatie wordt geïnformeerd dat je een beoordeling hebt gegeven en mag een reactie geven op je beoordeling. Ook deze eventuele reactie tonen we op onze website."
    }]
  })), __jsx(_grid.Box, {
    my: [5, 7]
  }, __jsx(_Title.default, {
    elementType: "h3",
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "kTCiHG",
    defaultMessage: [{
      "type": 0,
      "value": "Heb je een klacht?"
    }]
  })), __jsx(Description, null, __jsx(_reactIntl.FormattedMessage, {
    id: "KFAg2m",
    defaultMessage: [{
      "type": 0,
      "value": "Hopelijk was alles naar wens in de accommodatie. Toch horen we het ook graag als dat niet zo is. Vul dan "
    }, {
      "type": 1,
      "value": "complaintForm"
    }, {
      "type": 0,
      "value": " in. Wij geven je klacht direct door aan de verhuurder. Uiterlijk binnen twee weken ontvangt je een reactie."
    }],
    values: {
      complaintForm: __jsx(_TextButton.default, {
        style: {
          display: 'inline-flex'
        },
        as: "a",
        href: brandConfig.complaintFormUrl[locale],
        target: "_blank",
        rel: "noreferrer noopener"
      }, __jsx(_reactIntl.FormattedMessage, {
        id: "Cnhp1E",
        defaultMessage: [{
          "type": 0,
          "value": "het klachtenformulier"
        }]
      }))
    }
  }))), __jsx(_Divider.default, {
    className: "lt-s",
    mb: 6
  })), isAnonymous ? __jsx(_grid.Box, {
    order: 1,
    mb: 6,
    ml: [3, null, 4],
    width: [1, null, '60%']
  }, loading && __jsx(_grid.Box, null, __jsx(_UnitThumbnail.default, {
    loading: true
  }), __jsx(_Loading.default, {
    mt: 4,
    spacing: 3
  })), rentalUnit && __jsx(_SummaryBarItem.default, {
    thumbnail: __jsx(_SummaryBarItem.SummaryBarItemThumbnail, {
      isClickable: true,
      onClick: () => {
        push(`/${accommodationPageSlug}/${rentalUnit.slug}`);
      },
      title: rentalUnit.name,
      thumbnail: {
        sources: [{
          srcSet: rentalUnit.webpThumbnail.transform?.srcSet,
          type: 'image/webp'
        }, {
          srcSet: rentalUnit.jpegThumbnail.transform?.srcSet,
          type: 'image/jpeg'
        }],
        fallback: {
          src: rentalUnit.jpegThumbnail.transform?.src
        },
        placeholder: (0, _createPlaceholder.default)(rentalUnit.webpThumbnail.transform?.placeholder)
      },
      strapline: rentalUnit.accommodation.hasPublicPage && rentalUnit.accommodation.name || undefined,
      subTitle: __jsx(_SummaryBarItem.SummaryBarItemBody, {
        style: {
          textTransform: 'capitalize'
        },
        title: (0, _getUnitTypeConsts.default)(intl)[rentalUnit.type].translation
      }, (0, _getUnitTypeConsts.default)(intl)[rentalUnit.type].translation)
    })
  }, __jsx(_SummaryBarItem.SummaryBarItemField, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "B0YRgl",
      defaultMessage: [{
        "type": 0,
        "value": "# Accommodatie"
      }]
    })
  }, __jsx(_Body.default, {
    variant: "small"
  }, rentalUnit?.code)))) : __jsx(_grid.Box, {
    order: 1,
    mb: 6,
    ml: [3, null, 4],
    width: [1, null, '60%']
  }, __jsx(_SummaryBar.default, {
    data: booking,
    showOnMobile: true,
    hidePrices: true
  }))), __jsx(_grid.Box, {
    order: 2,
    width: ['100%', null, '70%', '60%']
  }, __jsx(_Divider.default, {
    mt: 0,
    mb: 8
  }), __jsx(_ReviewForm.default, {
    handleSubmit: handleSubmit
  })));
};
var _default = exports.default = LeaveReview;
const StyledTitleWrapper = (0, _styledComponents.default)(_TitleWrapper.default).withConfig({
  displayName: "LeaveReview__StyledTitleWrapper",
  componentId: "txm76o-0"
})(["@media screen and (min-width:", "){text-align:unset;}"], ({
  theme
}) => theme.mediaQueries.s);
const Description = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "LeaveReview__Description",
  componentId: "txm76o-1"
})(["margin-top:1.9rem;@media screen and (min-width:", "){margin-top:2.7rem;}"], ({
  theme
}) => theme.mediaQueries.m);